/*--------------------------------

nucleo-icons Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  src: url('../fonts/nucleo-icons.eot');
  src: url('../fonts/nucleo-icons.eot') format('embedded-opentype'), url('../fonts/nucleo-icons.woff2') format('woff2'), url('../fonts/nucleo-icons.woff') format('woff'), url('../fonts/nucleo-icons.ttf') format('truetype'), url('../fonts/nucleo-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.ni {
  display: inline-block;
  font: normal normal normal 1em/1 'nucleo-icons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.ni-sm {
  font-size: 0.8em;
}
.ni-lg {
  font-size: 1.2em;
}
/* absolute units */
.ni-16 {
  font-size: 16px;
}
.ni-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.ni-bg-square,
.ni-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.ni-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.ni-ul {
  padding-left: 0;
  list-style-type: none;
}
.ni-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.ni-ul > li > .ni {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.ni-is-spinning {
  -webkit-animation: ni-spin 2s infinite linear;
  -moz-animation: ni-spin 2s infinite linear;
  animation: ni-spin 2s infinite linear;
}
@-webkit-keyframes ni-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes ni-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes ni-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.ni-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ni-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ni-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ni-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.ni-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.ni-chat-46::before {
  content: "\ea02";
}

.ni-grid-45::before {
  content: "\ea03";
}

.ni-home-52::before {
  content: "\ea04";
}

.ni-single-01::before {
  content: "\ea05";
}

.ni-settings-gear-63::before {
  content: "\ea0a";
}

.ni-ic_file_download_48px::before {
  content: "\ea0b";
}

.ni-ic_format_list_bulleted_48px::before {
  content: "\ea0c";
}

.ni-ic_location_on_24px::before {
  content: "\ea0d";
}

.ni-ic_location_on_48px::before {
  content: "\ea0e";
}

.ni-home-52-2::before {
  content: "\ea0f";
}

.ni-ic_format_list_bulleted_48px-2::before {
  content: "\ea10";
}

.ni-chat-46-2::before {
  content: "\ea11";
}

.ni-single-01-2::before {
  content: "\ea12";
}

.ni-favourite-31::before {
  content: "\ea13";
}

.ni-settings-gear-63-2::before {
  content: "\ea14";
}

.ni-camera-20::before {
  content: "\ea15";
}

.ni-ic_person_add_48px::before {
  content: "\ea16";
}

.ni-lock::before {
  content: "\ea17";
}

.ni-lock-open::before {
  content: "\ea18";
}

.ni-cart-simple::before {
  content: "\ea19";
}

.ni-lock-open-2::before {
  content: "\ea1b";
}

.ni-email-84-2::before {
  content: "\ea1c";
}

.ni-ic_add_48px::before {
  content: "\ea1d";
}

.ni-ic_attach_money_48px::before {
  content: "\ea1e";
}

.ni-single-folded-content::before {
  content: "\ea1f";
}

.ni-gift-2::before {
  content: "\ea20";
}

.ni-support-16::before {
  content: "\ea21";
}

.ni-ic_phone_iphone_48px::before {
  content: "\ea22";
}

.ni-ic_add_a_photo_48px::before {
  content: "\ea23";
}

.ni-layers-3::before {
  content: "\ea24";
}

.ni-grid-45-2::before {
  content: "\ea25";
}

.ni-ic_hourglass_empty_48px::before {
  content: "\ea26";
}

