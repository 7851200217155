@include media-breakpoint-up(lg) {
  .container-lg {
    //max-width: 1160px;
  }
}

.container {
  padding-right: 30px;
  padding-left: 30px;
}
