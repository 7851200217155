.body {
  font-weight: 400;
}

iframe {
  /* This fixes a mobile Safari bug for checkout form */

  height: 50px !important;
}

.hoverable {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

p {
  margin-bottom: 0;
}
.text-dark {
  color: #0d1537 !important;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: lighten($color: $secondary, $amount: 10);
}

.text-muted {
  color: #9da2b7 !important;
}
.form-control {
  color: #0d1537;
  font-weight: 600;
  font-size: 1.1em;
}
.text-gradient {
  background: linear-gradient(to right, $primary 0%, $secondary 100%);
  -webkit-background-clip: text;
  background-clip: "text";
  -webkit-text-fill-color: transparent;
}
.script-title {
  font-family: "Noe Display Bold", "Merriweather";
  font-weight: 700;
}

.btn {
  border-radius: 0.5rem;
}

.navbar-dark-font {
  .navbar-brand {
    color: rgba(0, 0, 0, 1);
  }

  .navbar-toggler {
    color: rgba(0, 0, 0, 1);
  }
  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }
  .navbar-nav .nav-link {
    color: $black;
  }
}

.headroom--not-top {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: theme-color("white") !important;
  box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

.elevation {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  margin-top: 100px;
  background-color: $primary;
  border-radius: 50%;
  border: 10px solid $primary;
  width: 30px;
  height: 30px;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.carousel-control-prev,
.carousel-control-next {
  width: 30px;
}

.crisp-edges {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.footer-big.bg-dark a:hover {
  color: #fff;
  text-decoration: underline;
}
